
import { LAYOUT_SET_NAV_OPEN_INDEX } from '@/store/mutation-types';

export default {
  name: 'SiteHeader',
  computed: {
    layout() {
      return this.$store.state.layout;
    },
    isPro() {
      return this.$store.state.layout.isPro;
    },
    rootLink() {
      return this.isPro
        ? this.layout?.header?.pro?.rootLink
        : this.layout?.header?.private?.rootLink;
    },
    nav() {
      return this.isPro
        ? this.layout?.header?.pro?.nav
        : this.layout?.header?.private?.nav;
    },
  },
  methods: {
    onLanguageSwitched(data) {
      this.$emit('langSwitched', data);
    },
    handleOpenMenuIndex(openIndex) {
      this.$store.commit(`layout/${LAYOUT_SET_NAV_OPEN_INDEX}`, openIndex);
    },
    handleOpenPanel(panel) {
      this.$emit('open-panel', panel);
    },
  },
};
