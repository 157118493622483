import { render, staticRenderFns } from "./index.vue?vue&type=template&id=57a0d6b9&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsSiteHeaderLight: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/site-header-light/site-header-light.vue').default,OrganismsSiteHeader: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/site-header/site-header.vue').default})
